.marketplaces_categories_chooser {
  li {
    display: block;
    margin-top: 0.5em;
    cursor: pointer;
  }
}

.marketplace_product_out_of_date {
  color: red;
}
